<template>
  <div class="vx-row mb-12 mt-2">
    <div
      v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
      <data-table
        v-if="isQueryDone"
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
        <template slot="tbody">
          <vs-tr :key="index" v-for="(record, index) in responseData.records">
            <vs-td>
              <vx-tooltip
                v-if="$store.getters['user/hasPermissions']('view')"
                text="View"
                style="width: fit-content"
              >
                <vs-button
                  color="primary"
                  type="line"
                  icon-pack="feather"
                  icon="icon-eye"
                  v-on:click.stop="showDetail(record)"
                />
              </vx-tooltip>
              <p v-else>No Action</p>
            </vs-td>
            <vs-td>{{ record.invoiceExchange.code }}</vs-td>
            <vs-td>{{ record.invoiceExchange.invoice_exchange_number }}</vs-td>
            <vs-td>{{ dateFormat(record.invoiceExchange.date) }}</vs-td>
            <vs-td>{{
              getTerritoryName(record.invoiceExchange.territory_id)
            }}</vs-td>
            <vs-td>
              Code : {{ record.invoiceExchange.client_code }} <br />
              Name : {{ record.invoiceExchange.client_name }}
            </vs-td>
            <vs-td>
              Code : {{ record.invoiceExchange.client_code }} <br />
              Name :{{ record.customer_address.contact_name }}
            </vs-td>
          </vs-tr>
        </template>
      </data-table>
    </div>
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <div class="flex flex-col">
          <div
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              size="small"
              v-on:click="closeDetail"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </div>
          <!-- <form-apply :payment="selectedData" @closeDetail="closeDetail" /> -->
          <detail_info :selectedInvoiceExhange="selectedData" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import detail_info from "./detail_info.vue";
export default {
  props: {
    territoryIDs: Array,
    selectedCustomer: Object,
    selectedDate: Object,
    invoiceExchangeStatus: Number,
    isQueryDone: Boolean,
    isFilter: Boolean,
  },
  components: {
    detail_info,
  },
  watch: {
    // territoryIDs() {
    //   this.reloadData(this.params);
    // },

    // selectedCustomer() {
    //   this.reloadData(this.params);
    // },

    // selectedDate() {
    //   console.log("selectedDate changed", this.selectedDate);
    //   this.reloadData(this.params);
    // },
    isFilter() {
      if (this.isFilter) {
        this.reloadData(this.params);
      }
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Action",
        },
        {
          text: "Code",
          sortable: false,
        },
        {
          text: "Nomor Tukar Faktur",
        },
        {
          text: "Tanggal Tukar Faktur",
        },
        {
          text: "Territory",
          value: "code",
          // width: '5%'
        },
        {
          text: "Sold To",
          sortable: false,
        },
        {
          text: "Ship To",
        },
      ],
      responseData: {},
      checkedAll: false,
      checked: [],
      dataChecked: [],
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
    };
  },
  methods: {
    dateFormat(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    edit(id) {
      this.$router.push({ path: "invoice-exchange/edit/" + id });
    },
    getTerritoryName(id) {
      if (id === 0) {
        return "";
      }
      const territories = JSON.parse(
        JSON.stringify(this.$store.state.operatingUnit.filter.territory)
      );

      const territory = territories.find(
        (territory) => territory.TerritoryID === id
      );

      return territory.TerritoryName
        ? `${territory.TerritoryCode} - ${territory.TerritoryName}`
        : "";
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      // console.log(
      //   "getters",
      //   this.$store.getters["operatingUnit/getSelectedTerritoryId"]
      // );
      // const territory_ids =
      //   this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice-exchange/data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            territory_ids: this.territoryIDs,
            status: 1,
            client_name:
              !this.selectedCustomer.name || this.selectedCustomer.name == "All"
                ? undefined
                : this.selectedCustomer.name,
            client_code:
              !this.selectedCustomer.code || this.selectedCustomer.code == "All"
                ? undefined
                : this.selectedCustomer.code,
            start_date: !this.selectedDate.startDate
              ? undefined
              : this.dateFormat(this.selectedDate.startDate),
            end_date: !this.selectedDate.endDate
              ? undefined
              : this.dateFormat(this.selectedDate.endDate),
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.responseData = resp.data;
            // this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    closeDetail(params = false) {
      // window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    showDetail(tr) {
      this.$router.push({
        path: "invoice-exchange/detail/" + tr.invoiceExchange.ID,
      });
    },
  },
};
</script>

<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 46%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
