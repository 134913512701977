var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vx-row mb-12 mt-2"},[_c('div',{class:[
      _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
      _vm.detailHide,
    ]},[(_vm.isQueryDone)?_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"tbody"},_vm._l((_vm.responseData.records),function(record,index){return _c('vs-tr',{key:index},[_c('vs-td',[(_vm.$store.getters['user/hasPermissions']('view'))?_c('vx-tooltip',{staticStyle:{"width":"fit-content"},attrs:{"text":"View"}},[_c('vs-button',{attrs:{"color":"primary","type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){$event.stopPropagation();return _vm.showDetail(record)}}})],1):_c('p',[_vm._v("No Action")])],1),_c('vs-td',[_vm._v(_vm._s(record.invoiceExchange.code))]),_c('vs-td',[_vm._v(_vm._s(record.invoiceExchange.invoice_exchange_number))]),_c('vs-td',[_vm._v(_vm._s(_vm.dateFormat(record.invoiceExchange.date)))]),_c('vs-td',[_vm._v(_vm._s(_vm.getTerritoryName(record.invoiceExchange.territory_id)))]),_c('vs-td',[_vm._v(" Code : "+_vm._s(record.invoiceExchange.client_code)+" "),_c('br'),_vm._v(" Name : "+_vm._s(record.invoiceExchange.client_name)+" ")]),_c('vs-td',[_vm._v(" Code : "+_vm._s(record.invoiceExchange.client_code)+" "),_c('br'),_vm._v(" Name :"+_vm._s(record.customer_address.contact_name)+" ")])],1)}),1)],2):_vm._e()],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
        _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),_c('detail_info',{attrs:{"selectedInvoiceExhange":_vm.selectedData}})],1)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }