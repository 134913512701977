<template>
  <div
    v-if="isQueryDone && $store.getters['user/hasPermissions']('create')"
    class="flex flex-col gap-6 mt-3"
  >
    <div class="w-1/3" style="min-width: 150px">
      <vs-button @click="createInvoiceExchange"
        >Create Invoice Exchange</vs-button
      >
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="thead">
        <th width="7%">
          <vs-checkbox v-model="checkedAll">Select All</vs-checkbox>
        </th>
      </template>
      <template slot="tbody">
        <vs-tr :key="index" v-for="(record, index) in responseData.invoices">
          <vs-td
            ><vs-checkbox
              v-on:click="check(record.invoice.ID, index)"
              :checked="checked.includes(record.invoice.ID)"
            ></vs-checkbox>
          </vs-td>
          <vs-td>
            Code : {{ record.invoice.code }}
            <br />
            Faktur : {{ record.invoice.faktur_number }}
            <br />
            Invoice Reference Code : {{ record.invoice.invoice_reference_code }}
            <br />
            Date : {{ dateFormat(record.invoice.date) }}
            <br />
            Due Date : {{ dateFormat(record.invoice.due_date) }}
            <br />
            <!-- Posting Date : {{ dateFormat(invoice.PostingDate) }}
            <br /> -->
            Created Date : {{ dateFormat(record.invoice.CreatedAt) }}
          </vs-td>
          <vs-td>
            Name : {{ record.invoice.client_name }}
            <br />
            Code : {{ record.invoice.client_code }}
            <br />
            Territory : {{ getTerritoryName(record.invoice.territory_id) }}

            <br />
          </vs-td>
          <vs-td>
            Name : {{ record.customer_address.contact_name }}
            <br />
            Code : {{ record.customer_address.code }}
            <br />
            Address :
            {{
              `${record.customer_address.address}, ${record.customer_address.sub_district}, ${record.customer_address.sub_district}, ${record.customer_address.city}`
            }}
            <br />
            Territory :
            {{ getTerritoryName(record.customer_address.territory_id) }}
            <br />
          </vs-td>
          <vs-td>
            Subtotal : {{ priceFormat(record.invoice.sub_total) }}
            <br />
            Discount : {{ priceFormat(record.invoice.discount) }}
            <br />
            Subtotal Charge : {{ priceFormat(record.invoice.sub_total) }}
            <br />
            Tax : {{ priceFormat(record.invoice.tax_amount) }}
            <br />
            Total : {{ priceFormat(record.invoice.total) }}
            <br />
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    territoryIDs: Array,
    dcIDs: Array,
    selectedCustomer: Object,
    selectedDate: Object,
    isQueryDone: Boolean,
    invoiceCodes: Array,
    isFilter: Boolean,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Invoice",
          value: "code",
          // width: '5%'
        },
        {
          text: "Sold To",
          sortable: false,
        },
        {
          text: "Ship To",
          sortable: false,
        },
        {
          text: "Amount",
          sortable: false,
        },
      ],
      responseData: {},
      checkedAll: false,
      clientCodes: [],
      checked: [],
    };
  },
  watch: {
    checkedAll() {
      this.checkAll();
    },
    isFilter() {
      if (this.isFilter) {
        this.reloadData(this.params);
      }
    },
    // territoryIDs() {
    //   this.reloadData(this.params);
    // },
    // dcIDs() {
    //   this.reloadData(this.params);
    // },
    // selectedCustomer() {
    //   this.reloadData(this.params);
    // },
    // selectedDate() {
    //   this.reloadData(this.params);
    // },
    // invoiceCodes() {
    //   this.reloadData(this.params);
    // },
  },
  methods: {
    reloadData(params) {
      this.params = params;
      console.log(params);
      // console.log(
      //   "getters",
      //   this.$store.getters["operatingUnit/getSelectedTerritoryId"]
      // );
      // const territory_ids =
      //   this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      let codes = this.invoiceCodes;
      if (this.invoiceCodes.length > 0) {
        codes = this.invoiceCodes.map((data) => data.code);
      }
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/exchange-data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            territory_ids: this.territoryIDs,
            status_in: [1, 2, 3],
            customer_name:
              !this.selectedCustomer.name || this.selectedCustomer.name == "All"
                ? undefined
                : this.selectedCustomer.name,
            customer_code:
              !this.selectedCustomer.code || this.selectedCustomer.code == "All"
                ? undefined
                : this.selectedCustomer.code,
            start_date: !this.selectedDate.startDate
              ? undefined
              : this.dateFormatApi(this.selectedDate.startDate),
            end_date: !this.selectedDate.endDate
              ? undefined
              : this.dateFormatApi(this.selectedDate.endDate),
            codes: codes,
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.responseData = resp.data;
            console.log(this.responseData);
            // this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
    check(val, index) {
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        const codeIndex = this.clientCodes.findIndex((v) => v.id === val);
        this.clientCodes.splice(codeIndex, 1);
      } else {
        this.checked.push(val);
        const obj = {
          id: val,
          code: this.responseData.invoices[index].invoice.client_code,
        };
        this.clientCodes.push(obj);
      }

      console.log(this.clientCodes, "this.checked");
    },
    dateFormat(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    dateFormatApi(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    checkAll() {
      if (this.checkedAll) {
        this.checked = this.responseData.invoices.map((v) => v.invoice.ID);
        this.clientCodes = this.responseData.invoices.map((v) => ({
          id: v.invoice.ID,
          code: v.invoice.client_code,
        }));
      } else {
        this.checked = [];
        this.clientCodes = [];
      }
      // console.log(this.clientCodes);
    },
    getTerritoryName(territoryID) {
      const territory = [...this.$store.state.operatingUnit.filter.territory];
      const currentTerritory = territory.find(
        (v) => v.TerritoryID === territoryID
      );
      return currentTerritory
        ? `${currentTerritory.TerritoryCode} - ${currentTerritory.TerritoryName}`
        : "";
    },
    createInvoiceExchange() {
      if (this.checked.length == 0) {
        this.$vs.notify({
          title: "Invoice exchange creation",
          text: "Please select at least one invoice",
          position: "top-right",
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      for (let i = 0; i < this.clientCodes.length; i++) {
        if (this.clientCodes[0].code !== this.clientCodes[i].code) {
          this.$vs.notify({
            title: "Invoice exchange creation",
            text: "Please select invoices with the same customer",
            position: "top-right",
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      }

      this.$router.push({
        path: `invoice-exchange/create/${JSON.stringify(this.checked)}`,
      });
    },
  },
};
</script>
